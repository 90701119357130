@import "../../styles/variables"

.linkedin-post
    p, h1, h2, h3, h4, h5, h6
        margin: 0
        padding: 0
    display: flex
    flex-direction: column
    justify-content: center
    width: 1200px
    height: 628px
    padding: 0 60px
    background-image: url('../../../public/images/wave.png')
    .linkedin-post-title
        margin-bottom: 12px
        font-size: 28px
        font-family: $font-proximanova
        line-height: 34px
        font-weight: bold
        color: #D69A2D
    .linkedin-post-description
        font-size: 24px
        line-height: 30px
        font-family: $font-proximanova
        color: #343434
    .linkedin-post-content
        width: 100%
        display: flex
        flex-direction: row
        background-color: white
        border-radius: 8px
    .linkedin-post-content-left
        height: 442px
        width: 50%
        display: flex
        flex-direction: column
        justify-content: space-between
        padding: 25px 32px 32px 32px
        border-right: 4px solid #F5F5F5
    .linkedin-post-date
        font-size: 26px
        font-family: $font-proximanova
        line-height: 32px
        font-weight: 400
        color: #343434
    .linkedin-post-count
        margin-bottom: 8px
        font-size: 80px
        line-height: 80px
        letter-spacing: 14px
        font-weight: bold
        font-family: $font-proximanova
        color: #008C95
    .linkedin-post-usefull-logo
        margin-left: -3px
        margin-bottom: 16px
        max-height: 40px
        max-width: 214px
        width: 100%
        height: 100%
        object-fit: contain
    .linkedin-post-containers
        font-size: 26px
        font-family: $font-proximanova
        line-height: 26px
        color: #343434
    .linkedin-post-content-right
        width: 50%
    .linkedin-post-stats-header
        padding: 29px 66px
        border-bottom: 2px solid #F5F5F5
    .linkedin-post-stats-title
        font-size: 28px
        line-height: 34px
        font-weight: bold
        font-family: $font-proximanova
        text-align: center
        color: #484848
    .linkedin-post-image
        object-fit: contain
        text-align: center
    .linkedin-post-stats-row
        height: 117px
        display: flex
        flex-direction: row
        border-bottom: 2px solid #F5F5F5
        &:last-child
            border-bottom: none
    .linkedin-post-stats-column-left
        width: 40%
        display: flex
        align-items: center
        justify-content: center
        border-right: 2px solid #F5F5F5
    .linkedin-post-stats-column-right
        width: 60%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
    .linkedin-post-stats-value
        margin-bottom: 4px
        font-size: 26px
        line-height: 26px
        font-weight: bold
        font-family: $font-proximanova
        text-align: center
        text-transform: uppercase
        color: #484848
    .linkedin-post-stats-label
        font-size: 26px
        line-height: 26px
        font-weight: 400
        font-family: $font-proximanova
        text-align: center
        color: #484848
