.assignment
    padding-left: 10px
    .assignment-select
        width: 200px
    .assignment-space
        display: inline-block
        &.space-lg
            width: 80px
        &.space-xs
            width: 8px

