@import "../../styles/variables"

.shp-container
    p, h1, h2, h3, h4, h5, h6
        margin: 0
        padding: 0
    flex: 1
    display: flex
    flex-direction: column
    position: relative
    padding: 20px 20px 60px 20px
    .shp-sticky-content
        margin-bottom: 20px
    .shp-control
        display: flex
        flex-direction: row
    .shp-control-select
        width: 300px
        margin-right: 8px
    .shp-instructions
        margin-bottom: 32px
    .shp-instructions-title
        margin-bottom: 4px
        font-size: 22px
        font-family: $font-proximanova
        line-height: 28px
        font-weight: bold
        color: #343434
    .shp-instructions-description
        font-size: 18px
        line-height: 24px
        font-family: $font-proximanova
        color: #343434
    .shp-content
        display: inline-block