// Fonts
$font-lato: Lato, sans-serif
$font-merriweather: Merriweather, sans-serif
$font-proximanova: ProximaNova, sans-serif

// Colors
$primary-color: #008C95
$color-powder-ash: #BEC6C3
$color-alabaster: #F9F9F9
$color-limed-spruce: #333E48
$color-oslo-gray: #7D868C
$color-main-selected: #008C95
$color-main-background-selected: #DFEFEF

$color-warning: rgba(255, 151, 0, 0.59)
$color-danger: rgba(255, 29, 14, 0.59)

.ant-layout
  background: white
