@import "../../styles/variables.sass"

.App
  min-height: 100vh
  height: 100%

  .App-content
    background: white

    @media (min-width: 813px)

  .warning
    background: $color-warning !important

  .danger
    background: $color-danger !important

  .padding-15px
    padding: 15px

  .no-padding
    padding: 0
