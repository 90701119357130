@import "../../styles/variables"

.verify-email-container
    display: flex
    align-items: center
    justify-content: center
    padding: 15px
    height: 100vh
    background-image: url("./images/wave.png")
    background-color: #8FCCC8

    p, h1, h2, h3
        margin: 0
        padding: 0

    .verify-email-modal
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        padding: 60px 40px
        background-color: white
        border-radius: 2px

    .verify-email-icon
        margin-bottom: 52px
        font-size: 78px
        color: $primary-color

    .verify-email-icon-success
        color: $primary-color

    .verify-email-icon-fail
        color: $color-danger


    .verify-email-title
        margin-bottom: 12px
        font-size: 32px
        line-height: 38px
        letter-spacing: 1.5px
        font-weight: 700
        font-family: $font-merriweather
        text-align: center

    .verify-email-subtitle
        font-size: 24px
        line-height: 32px
        letter-spacing: 1.5px
        font-family: $font-merriweather
        text-align: center
        color: $color-oslo-gray
