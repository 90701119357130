@import "../../styles/variables"

.story
    p, h1, h2, h3, h4, h5, h6
        margin: 0
        padding: 0
    display: flex
    flex-direction: column
    justify-content: center
    width: 1080px
    height: 1920px
    padding: 0 68px
    background-image: url('../../../public/images/wave.png')
    .story-content-top
        padding: 72px 60px 60px 90px
        margin-bottom: 70px
        border-bottom: 8px solid #DDF0EE
        text-align: center
    .story-title
        margin-bottom: 30px
        font-size: 64px
        font-family: $font-proximanova
        line-height: 78px
        font-weight: bold
        text-align: center
        color: #484848
    .story-footer
        padding: 80px 0
    .story-description
        font-size: 40px
        font-family: $font-proximanova
        line-height: 48px
        font-weight: 600
        color: #343434
        text-align: center
    .story-content
        width: 100%
        display: flex
        flex-direction: column
        background-color: white
        border-radius: 8px
    .story-date
        margin-bottom: 40px
        font-size: 48px
        font-family: $font-proximanova
        line-height: 58px
        font-weight: 400
        text-align: center
        color: #343434
    .story-count
        margin-bottom: 12px
        font-size: 132px
        line-height: 132px
        letter-spacing: 12px
        font-weight: bold
        font-family: $font-proximanova
        text-align: center
        color: #008C95
    .story-usefull-logo
        margin-bottom: 26px
        max-height: 66px
        max-width: 354px
        width: 100%
        height: 100%
        object-fit: contain
    .story-containers
        font-size: 48px
        font-family: $font-proximanova
        line-height: 58px
        font-weight: 400
        text-align: center
        color: #343434
    .story-stats-title
        margin-bottom: 40px
        font-size: 56px
        font-family: $font-proximanova
        line-height: 68px
        font-weight: bold
        text-align: center
        color: #484848
    .story-post-stats
        display: flex
        flex-direction: row
        justify-content: space-between
        padding: 0 60px 90px 60px
    .story-post-stats-item
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
    .story-post-image
        margin-bottom: 30px
        object-fit: contain
        text-align: center
    .story-post-stats-value
        margin-bottom: 12px
        font-size: 48px
        line-height: 48px
        font-weight: bold
        font-family: $font-proximanova
        text-align: center
        text-transform: uppercase
        color: #484848
    .story-post-stats-label
        font-size: 40px
        line-height: 40px
        font-weight: 400
        font-family: $font-proximanova
        text-align: center
        color: #343434
