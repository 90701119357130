@import "../../styles/variables"

.sidebar
  z-index: 10
  background-color: $color-alabaster
  -webkit-box-shadow: 5px 0px 40px 0px rgba(125, 134, 140, 0.3)
  -moz-box-shadow: 5px 0px 40px 0px rgba(125, 134, 140, 0.3)
  box-shadow: 5px 0px 40px 0px rgba(125, 134, 140, 0.3)

  @media (max-width: 813px)
    padding-bottom: 130px

  .sidebar-header
    display: flex
    justify-content: space-between
    align-items: center
    padding-left: 15px
    padding-right: 15px

    &.collapsed
      flex-direction: column
      justify-content: normal

  &__logo
    width: 145px
    margin: 25px 0
    align-self: center
    justify-content: left

  &__logo-small
    width: 28px
    margin: 25px 0
    align-self: center
    justify-content: left

    @media (max-height: 375px)
      width: 28px
      margin: 10px 0

  .ant-layout-sider-children
      display: flex
      flex-direction: column

  &.ant-layout-sider-collapsed

    .ant-layout-sider-children
      display: flex
      flex-direction: column

  .ant-menu
    overflow-y: auto
    overflow-x: hidden
    //min-height: calc(100vh - 100px) // 100vh - height of header
    //padding-left: 10px
    //
    //display: flex
    //flex-direction: column
    //justify-content: center

    background-color: transparent
    border: none

    &-submenu-title
      margin-left: 0
      margin-right: 0
      border-radius: 0
      width: 100%
      border-right: 3px solid
      border-color: transparent


    .ant-menu-title-content
      font-size: 15px !important
      padding-left: 5px

    @media (max-height: 375px)
      .ant-menu-item
        margin-bottom: 0
        margin-top: 0

    &-inline-collapsed
      width: 100px
      border-right: 0

    &-item
      font-size: 15px
      margin-left: 0
      margin-right: 0
      border-radius: 0
      width: 100%
      border-right: 3px solid
      border-color: transparent

      &-icon
        padding-top: 2px

      svg
        width: 15px
        height: 15px
      path
        fill: $color-oslo-gray

      &:first-child
        @media (max-width: 768px) and (orientation: landscape)
          margin-top: auto
          margin-bottom: auto

      &-selected
        background-color: $color-main-background-selected !important
        border-color: $color-main-selected


        svg
          width: 15px
          height: 15px

        path
          fill: $color-main-selected

    &-item-group-title
      padding-left: 26px

  &-trigger
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    width: 15px
    height: 60px
    right: 0
    transform: translateX(100%)
    top: 119px
    background-color: $color-main-selected
    color: white
    cursor: pointer
    clip-path: polygon(0 0, 100% 13%, 100% 87%, 0 100%)
    -webkit-box-shadow: 20px 3px 12px 20px rgba(0,0,0,0.75)
    -moz-box-shadow: 20px 3px 12px 20px rgba(0,0,0,0.75)
    box-shadow: 20px 3px 12px 20px rgba(0,0,0,0.75)

    &-icon
      font-size: 12px
      transition: transform 0.3s ease




.ant-popover-arrow
  left: 18px !important
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%) !important

.ant-popover-content
  left: 18px

.ant-popover-inner
  border-radius: 0 !important
  padding-left: 16px !important

.popover-user-info
  cursor: pointer

.popover-user-info-drop-down
  width: 236px

  a
    display: flex
    color: black

    svg
      margin-right: 10px

  a:hover
    color: $color-main-selected
    stroke: $color-main-selected !important

.user-name
  font-weight: bold

.user-email
  display: block

.user-name, .user-email
  width: 234px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.dropdown-user-info
  margin-bottom: 15px

.ant-menu-item-icon
  padding-left: 4px
  padding-bottom: 5px

