@import "../../styles/variables"

.insta-post
    p, h1, h2, h3, h4, h5, h6
        margin: 0
        padding: 0
    display: flex
    flex-direction: column
    justify-content: center
    width: 1080px
    height: 1080px
    padding: 0 60px
    background-image: url('../../../public/images/wave.png')
    .insta-post-usefull-logo
        margin-top: 12px
        margin-bottom: 20px
        max-height: 48px
        max-width: 256px
        width: 100%
        height: 100%
        object-fit: contain
    .insta-post-title
        font-size: 48px
        font-family: $font-proximanova
        line-height: 58px
        font-weight: bold
        text-align: center
        color: #484848
    .insta-post-description
        font-size: 30px
        font-family: $font-proximanova
        line-height: 36px
        font-weight: bold
        color: #484848
        text-align: center
    .insta-post-content
        width: 100%
        display: flex
        flex-direction: column
        background-color: white
        border-radius: 8px
        margin-bottom: 32px
    .insta-post-content-top
        padding: 32px 70px
        text-align: center
    .insta-post-content-divider
        position: relative
        height: 4px
        width: 97%
        left: 3%
        margin-bottom: 32px
        background-color: #F5F5F5
    .insta-post-date
        margin: 14px 0
        font-size: 32px
        font-family: $font-proximanova
        line-height: 38px
        font-weight: 400
        text-align: center
        color: #484848
    .insta-post-count
        font-size: 108px
        line-height: 108px
        letter-spacing: 14px
        font-weight: bold
        font-family: $font-proximanova
        text-align: center
        color: #008C95
    .insta-post-containers
        font-size: 32px
        font-family: $font-proximanova
        line-height: 38px
        font-weight: 400
        text-align: center
        color: #343434
    .insta-post-content-bottom
        padding-bottom: 40px
    .insta-post-stats-title
        margin-bottom: 24px
        font-size: 36px
        line-height: 36px
        font-weight: bold
        font-family: $font-proximanova
        text-align: center
        color: #484848
    .insta-post-image
        margin-bottom: 24px
        object-fit: contain
        text-align: center
    .insta-post-stats
        display: flex
        flex-direction: row
        justify-content: space-between
        padding: 0 68px
    .insta-post-stats-item
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
    .insta-post-stats-value
        margin-bottom: 8px
        font-size: 40px
        line-height: 40px
        font-weight: bold
        font-family: $font-proximanova
        text-align: center
        text-transform: uppercase
        color: #484848
    .insta-post-stats-label
        font-size: 36px
        line-height: 36px
        font-weight: 400
        font-family: $font-proximanova
        text-align: center
        color: #343434
