@import "../../styles/variables"

body
  margin: 0

.cc-hosted-page
  display: flex
  justify-content: center
  background: #deefef

  p, h1, h2, h3
    margin: 0
    padding: 0

  a
    text-decoration: none
    margin: 0
    padding: 0
    border: 0

  .cc-hosted-page-content
    display: flex
    flex-direction: column
    max-width: 1080px
    padding: 40px 60px
    background-image: url("./images/wave.png")
    background-color: #8FCCC8

    @media (orientation: landscape)
      max-width: 1350px

    @media (max-width: 672px)
      height: auto
      padding: 30px 15px 60px 15px

  .cc-hosted-page-header
    min-height: 125px
    margin-bottom: 20px

    @media (orientation: landscape)
      margin-bottom: 40px

  .cc-hosted-page-header,
  .cc-hosted-page-footer
    text-align: center

  .cc-hosted-page-footer
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    position: relative
    margin-top: 20px
    padding: 0 140px

    & > div:nth-child(1)
      position: absolute
      bottom: 0
      left: 0

    & > div:nth-child(3)
      margin-top: 40px

    @media (orientation: landscape)
      flex-direction: row
      align-items: flex-end
      justify-content: space-between
      position: relative
      margin-top: 40px
      padding: 0

      & > div
        margin-right: 44px

        &:last-child
          margin-right: 0

        &:nth-child(1)
          position: relative
          left: auto
          bottom: auto

        &:nth-child(3)
          margin-top: 0

    @media (max-width: 672px)
      align-items: flex-end
      justify-content: flex-end
      padding: 0 0 0 120px

      & > div:nth-child(3)
        margin-top: 20px

    @media (max-width: 672px) and (orientation: landscape)
      padding: 0
      align-items: flex-end
      justify-content: space-between

  .cc-client-logo-image
    width: 100%
    max-width: 500px
    height: 100%
    max-height: 125px
    object-fit: contain

  .cc-client-footer-logo
    @media (max-width: 672px)
      max-width: 200px

    @media (max-width: 672px) and (orientation: landscape)
      max-width: 500px

  .cc-hosted-page-info
    display: flex
    flex-direction: column
    padding: 50px
    background-color: #FFF

    @media (orientation: landscape)
      flex-direction: row
      padding: 60px

    @media (max-width: 672px)
      padding: 30px

  .cc-hosted-page-title
    margin-bottom: 20px
    font-size: 42px
    line-height: 40px
    letter-spacing: 1.5px
    font-weight: 700
    font-family: $font-merriweather
    color: #e17e3c

    @media (orientation: landscape)
      line-height: 56px

    @media (max-width: 672px)
      font-size: 28px
      line-height: 32px

  .cc-hosted-page-date
    font-size: 26px
    font-weight: 600
    letter-spacing: 1.5px
    font-family: $font-lato
    text-transform: uppercase
    color: #4b4c4d
    line-height: 1.5715

    @media (max-width: 672px)
      font-size: 20px
      line-height: 22px

  .cc-hosed-page-count
    font-size: 64px
    font-weight: 700
    letter-spacing: 10px
    text-transform: uppercase
    color: #068996
    font-family: $font-lato
    line-height: 1.5715

    @media (max-width: 672px)
      font-size: 52px

  .cc-hosted-page-text
    font-size: 24px
    letter-spacing: 1.2px
    font-family: $font-lato
    color: #57585a
    line-height: 1.5715

    @media (max-width: 672px)
      font-size: 18px
      line-height: 21px

  .cc-hosted-page-impact
    text-align: center
    margin-bottom: 40px
    padding-bottom: 40px
    border-bottom: 1px solid #edece8

    @media (orientation: landscape)
      flex: 2
      border-bottom: 0
      margin-bottom: 0
      padding-bottom: 0
      margin-right: 50px
      padding-right: 50px
      border-right: 1px solid #edece8

  .cc-hosted-page-saved
    text-align: center

    @media (orientation: landscape)
      flex: 8

  .saved-items
    display: flex
    flex-direction: row
    margin-bottom: 60px

    @media (max-width: 672px)
      flex-direction: column

    .saved-item
      flex: 1 0 33%
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      margin-right: 8px

      &:last-child
        margin-right: 0

      @media (max-width: 672px)
        margin-right: 0
        margin-bottom: 20px

        &:last-child
          margin-bottom: 0

    .saved-item-image-container
      width: 100%
      max-width: 200px
      height: 100%
      max-height: 250px

      @media (max-width: 672px)
        max-width: 150px
        max-height: 150px

    .saved-item-image
      width: 100%
      height: 100%
      object-fit: contain

    .saved-item-label
      font-size: 20px
      letter-spacing: 1.2px
      font-family: $font-lato
      font-weight: 600
      text-transform: uppercase
      color: #717275
      line-height: 1.5715

      @media (max-width: 672px)
        margin-top: 8px
        font-size: 16px
        line-height: 18px

  .saved-item-description
    font-size: 24px
    line-height: 32px
    font-weight: bold
    letter-spacing: 1.5px
    font-family: $font-merriweather
    text-align: center
    color: #4b4c4d

    @media (max-width: 672px)
      font-size: 18px
      line-height: 21px

  .cc-hosted-page-usefull
    color: #4b4c4d
    font-size: 20px
    line-height: 20px
    font-weight: 700
    font-family: $font-lato
    letter-spacing: 1.5px

  .cc-client-qr-code
    max-width: 75px
    max-height: 75px
    width: 100%
    height: 100%

    @media (orientation: landscape)
      max-width: 100px
      max-height: 100px

.cc-hosted-page-panel
  padding-left: 15px
  padding-right: 15px
  background-color: $color-main-selected
  height: 30px
  color: white
  display: flex
  align-items: center

  div
    span:first-child
      margin-right: 10px
      width: 15px
      height: 15px

    span:nth-child(2)
      font-weight: bold
      margin-right: 5px

    span:nth-child(3)
     margin-right: 10px

    span:nth-child(4)
      width: 15px
      height: 15px

    a
      color: white
      font-weight: bold

    path
     fill: white

.tv-link-wrapper, .shareable-image-wrapper
  display: flex
  align-items: center

.space-between
  justify-content: space-between

.move-right
  justify-content: right