@import "../../styles/variables"

.auth-wrapper
  height: 100vh

  font-family: $font-proximanova
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

  text-align: center

  > #firebaseui_container li.firebaseui-list-item button[data-provider-id="apple.com"]
    background-color: black

  &__logo
    width: 180px

  &__title
    margin: 30px 0

    font-size: 20px
    font-weight: 400
    font-family: $font-proximanova

  &__subtitle
    margin-top: 20px
    a
      color: $primary-color

  .ant-form-item
    margin-bottom: 10px

    input
      width: 280px

  .button-primary
    margin-bottom: 20px

.error-message-text
  color: $color-danger

.signup-loader
  margin-left: 10px

#password, #passwordRepeat
  width: 244px

#firebaseui_container .firebaseui-id-secondary-link, shark-icon-container
  color: $primary-color !important

#firebaseui_container .firebaseui-input, .mdl-button, span, h1, input, h2, p, strong, button
  font-family: $font-proximanova !important

#firebaseui_container .firebaseui-id-submit, .firebaseui-textfield.mdl-textfield .firebaseui-label::after
  background-color: $primary-color !important
